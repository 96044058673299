var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card p-2"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('h3',{staticClass:"text-primary font-weight-bolder mb-0 mr-50"},[_vm._v(" Commission ")]),_c('b-form-checkbox',{attrs:{"name":"check-button","switch":"","inline":""},model:{value:(_vm.data.status),callback:function ($$v) {_vm.$set(_vm.data, "status", $$v)},expression:"data.status"}},[_vm._v(" "+_vm._s(_vm.commission ? "เปิดใช้งาน" : "ปิดใช้งาน")+" ")])],1),_c('validation-observer',{ref:"commissionRules",staticClass:"my-2"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"t1"}},[_vm._v("Calculate Type "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"small text-info"},[_vm._v(" ผู้เล่นสามารถตรับได้พุกครั่งที่มียอด Commission ที่ยังไม่ได้รับ ")]),_c('validation-provider',{attrs:{"name":"calculate type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"Calculate Type","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.option_cal,"reduce":function (title) { return title.value; },"state":errors.length > 0 ? false : null},model:{value:(_vm.data.calculate_type),callback:function ($$v) {_vm.$set(_vm.data, "calculate_type", $$v)},expression:"data.calculate_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}}),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"t1"}},[_vm._v("Number of times players can receive Cashback per day "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"small text-info"},[_vm._v(" (Set to O, nat specitying the nummber of times you press to receive Commission per day) ")]),_c('validation-provider',{attrs:{"name":"receive cashback","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"type":"number"},model:{value:(_vm.data.receive_cashback),callback:function ($$v) {_vm.$set(_vm.data, "receive_cashback", $$v)},expression:"data.receive_cashback"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}}),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"t1"}},[_vm._v("Minimum bet amount to receive commission "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"minimum receive commission","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"type":"number"},model:{value:(_vm.data.minimum_bet),callback:function ($$v) {_vm.$set(_vm.data, "minimum_bet", $$v)},expression:"data.minimum_bet"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"t1"}},[_vm._v("Limit Withdraw "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"small text-info"}),_c('validation-provider',{attrs:{"name":"limit withdraw","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"type":"number"},model:{value:(_vm.data.limit_withdraw),callback:function ($$v) {_vm.$set(_vm.data, "limit_withdraw", $$v)},expression:"data.limit_withdraw"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"t1"}},[_vm._v("Maximum amount of commission a user can earn per day "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"small text-info"},[_vm._v(" (Set to O ta nat specify the maximum amount received per User per day) ")]),_c('validation-provider',{attrs:{"name":"maximum commission day","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"type":"number"},model:{value:(_vm.data.max_commission_day),callback:function ($$v) {_vm.$set(_vm.data, "max_commission_day", $$v)},expression:"data.max_commission_day"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"t1"}},[_vm._v("Maximum amount of commission a user can earn per week "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"small text-info"},[_vm._v(" (Set to O to not specify the maximumn amount received per User per week.) ")]),_c('validation-provider',{attrs:{"name":"maximum commission week","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"type":"number"},model:{value:(_vm.data.max_commission_week),callback:function ($$v) {_vm.$set(_vm.data, "max_commission_week", $$v)},expression:"data.max_commission_week"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"t1"}},[_vm._v("Maximum amount of commission a user can earn per month "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"small text-info"},[_vm._v(" (Set to O to not specity the maximum amount received per User per month.) ")]),_c('validation-provider',{attrs:{"name":"maximum received month","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"type":"number"},model:{value:(_vm.data.max_commission_month),callback:function ($$v) {_vm.$set(_vm.data, "max_commission_month", $$v)},expression:"data.max_commission_month"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('h5',{staticClass:"text-info mt-2"},[_vm._v(" Percent(%) Commission From Valid Amount ")])]),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":"t1"}},[_vm._v("Slot"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"percent commission","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"type":"number"},model:{value:(_vm.data.percent_slot),callback:function ($$v) {_vm.$set(_vm.data, "percent_slot", $$v)},expression:"data.percent_slot"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":"t1"}},[_vm._v("Casino"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"saba","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"type":"number"},model:{value:(_vm.data.percent_casino),callback:function ($$v) {_vm.$set(_vm.data, "percent_casino", $$v)},expression:"data.percent_casino"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":"t1"}},[_vm._v("Sport"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"card","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"type":"number"},model:{value:(_vm.data.percent_sport),callback:function ($$v) {_vm.$set(_vm.data, "percent_sport", $$v)},expression:"data.percent_sport"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":"t1"}},[_vm._v("Lotto"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"lotto","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"type":"number"},model:{value:(_vm.data.percent_lotto),callback:function ($$v) {_vm.$set(_vm.data, "percent_lotto", $$v)},expression:"data.percent_lotto"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":"t1"}},[_vm._v("Trade"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"trade","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"type":"number"},model:{value:(_vm.data.percent_trade),callback:function ($$v) {_vm.$set(_vm.data, "percent_trade", $$v)},expression:"data.percent_trade"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":"t1"}},[_vm._v("Poker"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"pocker","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"type":"number"},model:{value:(_vm.data.percent_poker),callback:function ($$v) {_vm.$set(_vm.data, "percent_poker", $$v)},expression:"data.percent_poker"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":"t1"}},[_vm._v("Card"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Card","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"type":"number"},model:{value:(_vm.data.percent_card),callback:function ($$v) {_vm.$set(_vm.data, "percent_card", $$v)},expression:"data.percent_card"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"font-weight-bolder h5"},[_vm._v(" Poster Image (PNG, JPEG, JPG 1000*1000px) ")]),_c('div',{staticClass:"small"},[_vm._v(" Minimum upload not more 2MB. ")]),_c('div',{staticClass:"img-drag_and_drop",on:{"drop":_vm.handleDrop,"dragover":function($event){$event.preventDefault();},"dragenter":function($event){$event.preventDefault();},"dragleave":function($event){$event.preventDefault();}}},[(_vm.imageUrl)?_c('img',{attrs:{"src":_vm.imageUrl,"alt":"uploaded image"}}):_c('span',[_vm._v(" Drag & Drop or click to upload"),_c('br'),_c('input',{attrs:{"type":"file","accept":"image/png, image/jpeg"},on:{"change":_vm.handleFileUpload}})]),(_vm.errorMessage)?_c('span',{staticClass:"error"},[_vm._v(_vm._s(_vm.errorMessage))]):_vm._e(),(_vm.imageUrl)?_c('button',{staticClass:"delete-btn",on:{"click":_vm.clearImage}},[_c('i',{staticClass:"fa fa-trash"})]):_vm._e()])]),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"mt-1"},[_c('label',{attrs:{"for":"GameType"}},[_vm._v("Game Type"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Game Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"GameType","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.option,"reduce":function (title) { return title; },"state":errors.length > 0 ? false : null,"multiple":""},model:{value:(_vm.data.game_type),callback:function ($$v) {_vm.$set(_vm.data, "game_type", $$v)},expression:"data.game_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_vm._l((_vm.data.game_type),function(game){return _c('div',{key:game.title},[(game.title === 'Slot')?_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"Slot"}},[_vm._v("Slot "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Slot","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"type":"number"},model:{value:(_vm.data.turn_slot),callback:function ($$v) {_vm.$set(_vm.data, "turn_slot", $$v)},expression:"data.turn_slot"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"Type"}},[_vm._v("Type "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"Type","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.option_gametype,"reduce":function (title) { return title.value; },"state":errors.length > 0 ? false : null},model:{value:(_vm.data.type_slot),callback:function ($$v) {_vm.$set(_vm.data, "type_slot", $$v)},expression:"data.type_slot"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1):_vm._e(),(game.title === 'Casino')?_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"Casino"}},[_vm._v("Casino "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Lotto","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"type":"number"},model:{value:(_vm.data.turn_casino),callback:function ($$v) {_vm.$set(_vm.data, "turn_casino", $$v)},expression:"data.turn_casino"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"Type"}},[_vm._v("Type "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"Type","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.option_gametype,"reduce":function (title) { return title.value; },"state":errors.length > 0 ? false : null},model:{value:(_vm.data.type_casino),callback:function ($$v) {_vm.$set(_vm.data, "type_casino", $$v)},expression:"data.type_casino"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1):_vm._e(),(game.title === 'Sport')?_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"Sport"}},[_vm._v("Sport "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Lotto","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"type":"number"},model:{value:(_vm.data.turn_sport),callback:function ($$v) {_vm.$set(_vm.data, "turn_sport", $$v)},expression:"data.turn_sport"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"Type"}},[_vm._v("Type "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"Type","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.option_gametype,"reduce":function (title) { return title.value; },"state":errors.length > 0 ? false : null},model:{value:(_vm.data.type_sport),callback:function ($$v) {_vm.$set(_vm.data, "type_sport", $$v)},expression:"data.type_sport"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1):_vm._e(),(game.title === 'Lotto')?_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"Lotto"}},[_vm._v("Lotto "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Lotto","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"type":"number"},model:{value:(_vm.data.turn_lotto),callback:function ($$v) {_vm.$set(_vm.data, "turn_lotto", $$v)},expression:"data.turn_lotto"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"Type"}},[_vm._v("Type "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"Type","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.option_gametype,"reduce":function (title) { return title.value; },"state":errors.length > 0 ? false : null},model:{value:(_vm.data.type_lotto),callback:function ($$v) {_vm.$set(_vm.data, "type_lotto", $$v)},expression:"data.type_lotto"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1):_vm._e(),(game.title === 'Trade')?_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"Trade"}},[_vm._v("Trade "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Trade","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"type":"number"},model:{value:(_vm.data.turn_trade),callback:function ($$v) {_vm.$set(_vm.data, "turn_trade", $$v)},expression:"data.turn_trade"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"Type"}},[_vm._v("Type "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"Type","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.option_gametype,"reduce":function (title) { return title.value; },"state":errors.length > 0 ? false : null},model:{value:(_vm.data.type_trade),callback:function ($$v) {_vm.$set(_vm.data, "type_trade", $$v)},expression:"data.type_trade"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1):_vm._e(),(game.title === 'Poker')?_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"Poker"}},[_vm._v("Poker "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Poker","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"type":"number"},model:{value:(_vm.data.turn_poker),callback:function ($$v) {_vm.$set(_vm.data, "turn_poker", $$v)},expression:"data.turn_poker"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"Type"}},[_vm._v("Type "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"Type","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.option_gametype,"reduce":function (title) { return title.value; },"state":errors.length > 0 ? false : null},model:{value:(_vm.data.type_poker),callback:function ($$v) {_vm.$set(_vm.data, "type_poker", $$v)},expression:"data.type_poker"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1):_vm._e(),(game.title === 'Card')?_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"Card"}},[_vm._v("Card "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Card","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"type":"number"},model:{value:(_vm.data.turn_card),callback:function ($$v) {_vm.$set(_vm.data, "turn_card", $$v)},expression:"data.turn_card"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"Type"}},[_vm._v("Type "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"Type","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.option_gametype,"reduce":function (title) { return title.value; },"state":errors.length > 0 ? false : null},model:{value:(_vm.data.type_card),callback:function ($$v) {_vm.$set(_vm.data, "type_card", $$v)},expression:"data.type_card"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1):_vm._e(),(game.title === 'All')?_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"All"}},[_vm._v("All "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"All","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"type":"number"},model:{value:(_vm.data.turn_all),callback:function ($$v) {_vm.$set(_vm.data, "turn_all", $$v)},expression:"data.turn_all"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"Type"}},[_vm._v("Type "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"Type","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.option_gametype,"reduce":function (title) { return title.value; },"state":errors.length > 0 ? false : null},model:{value:(_vm.data.type_all),callback:function ($$v) {_vm.$set(_vm.data, "type_all", $$v)},expression:"data.type_all"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1):_vm._e()],1)})],2),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_vm._v(" Submit ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }